import React, { useState, useEffect } from "react";
import "./subSectionTwo.scss";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
//to be used in future logic
//import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import DonutChart from "../../../components/DonutChart/DonutChart";
//import CardTitleWSelect from "../../../components/CardTitleWSelect/CardTitleWSelect";
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";
import { roundToTwoDecimals } from "../../../helpers/currencyFormatter";
import {
  getOverviewData,
  getAssetDiversificationData,
} from "../../../services/FinancialServices";
import SimplifiedLineChart from "../../../components/SimplifiedLineChart/SimplifiedLineChart";
import { formatCurrency } from "../../../helpers/currencyFormatter";
import {
  getParticipantEvolutionData,
  getNavEvolutionData,
} from "../../../services/FinancialServices";

import HorizontalGradientBarChart from "../../../components/HorizontalGradientBarChart/HorizontalGradientBarChart";
//default models
import { initialParticipantsEvolutionModel } from "../../../models/ParticipantsEvolutionModel";
import { initialNavEvolutionModel } from "../../../models/NavEvolutionModel";
import { initialAssetDiversificationModel } from "../../../models/AssetDiversificationModel";
import { initialDasboardOverviewModel } from "../../../models/DasboardOverviewModel";
// translations
import { useTranslation } from "react-i18next";

export default function SubSectionTwo({ companyId, selectedPortfolioType }) {
  
  //let brandName = "Syngenta";
  const [dasboardOverviewModel, setDasboardOverviewModel] = useState(
    initialDasboardOverviewModel
  );
  const [assetDiversification, setAssetDiversification] = useState(
    initialAssetDiversificationModel
  );
  const [participantEvolution, setParticipantEvolution] = useState(
    initialParticipantsEvolutionModel
  );
  const [navEvolutionModel, setNavEvolutionModel] = useState(
    initialNavEvolutionModel
  );
  const { t } = useTranslation();

  //on selection change
  useEffect(() => {
    if (companyId && selectedPortfolioType) {
      getOverviewData(companyId, selectedPortfolioType.accountId).then(
        (res) => {
          const dashboardOverview = res?.data?.dashboardOverview;
          if (dashboardOverview) {
            setDasboardOverviewModel(dashboardOverview);
          }
        }
      );
      getAssetDiversificationData(
        companyId,
        selectedPortfolioType.accountId
      ).then((response) => {
        const dataResponse = response?.data?.assetDiversificationDTO;
        if (dataResponse) {
          setAssetDiversification(dataResponse);
        } else {
          setAssetDiversification([]);
        }
      });

      getParticipantEvolutionData(
        companyId,
        selectedPortfolioType.accountId
      ).then((response) => {
        const dataResponse = response?.data;
        if (dataResponse) {
          setParticipantEvolution(dataResponse);
        } else {
          setParticipantEvolution([]);
        }
      });
      getNavEvolutionData(companyId, selectedPortfolioType.accountId).then(
        (response) => {
          const dataResponse = response?.data;
          if (dataResponse) {
            setNavEvolutionModel(dataResponse);
          } else {
            setNavEvolutionModel([]);
          }
        }
      );
    }
  }, [companyId, selectedPortfolioType]);

  return (
    <div className="sectionTwo-container">
      {/* Seccion 1 */}
      <div className="u-displayFlex portfolio-firstline">
        <div className="u-displayFlex portfolio-lowres-firstline">
          <div className="base-data-card seccondary-data-card-bg u-justifyCenter u-marginAm u-alignItems">
            <div className="u-flexColumn">
              <label className="card-label">
                {t("Portfolio.sectionTwo.Valor Actual de la cartera")}
              </label>
              <label className="card-price">
                U$D{" "}
                {formatCurrency(dasboardOverviewModel.currentPortfolioValue)}.-
              </label>
            </div>
          </div>

          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-flexRow u-alignItems">
            <div className="card-icon-container">
              <BarChartIcon />
            </div>

            <div className="u-flexColumn u-marginHm">
              <label className="card-label u-color-title-card">
                {t("Portfolio.sectionTwo.Valor Inicial")} 2021
              </label>
              <label className="card-price">
                U$D {formatCurrency(dasboardOverviewModel.initialValue2021)}.-
              </label>
            </div>
          </div>
        </div>

        <div className="u-displayFlex portfolio-lowres-secondline">
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-flexRow u-alignItems">
            <div className="card-icon-container">
              <AttachMoneyIcon />
            </div>
            <div className="u-flexColumn u-marginHm">
              <label className="card-label u-color-title-card">
                {t("Portfolio.sectionTwo.Valor a principio de año")}
              </label>
              <label className="card-price">
                U$D {formatCurrency(dasboardOverviewModel.beginningOfYearValue)}
                .-
              </label>
            </div>
          </div>

          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex u-alignItems">
            <div className="u-flexColumn">
              <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                {t("Portfolio.sectionTwo.Rendimiento")} YTD $
                <div>
                  <DarkToolTip
                    content={t("Portfolio.sectionTwo.tooltip1")}
                    placement={"top"}
                  />
                </div>
              </label>
              <label className="card-price">
                U$D{" "}
                {formatCurrency(dasboardOverviewModel.ytdPerformance.valueUSD)}
                .-
                {dasboardOverviewModel.ytdPerformance.isPositive ? (
                  <span className="u-color-success">
                    <TrendingUpIcon />
                  </span>
                ) : (
                  <span className="u-color-danger">
                    <TrendingDownIcon />
                  </span>
                )}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="u-displayFlex subSectionTwo-container portfolio-middle-section">
        <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex nav-portfolio-card">
          <div className="u-flexColumn u-Width100">
            <h3 className="card-graph-title">
              {t("Portfolio.sectionTwo.Evolución de")} NAV - Portafolio{" "}
              {selectedPortfolioType.name}
            </h3>
            <div className="">
              <SimplifiedLineChart data={navEvolutionModel.nav} />
            </div>
          </div>
        </div>

        <div>
          {" "}
          <div className="right-row">
            <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex u-alignContentCenter portfolio-per-ytd">
              <div className="u-flexColumn">
                <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                  {t("Portfolio.sectionTwo.Rendimiento")} YTD %
                  <DarkToolTip
                    content={t("Portfolio.sectionTwo.tooltip1")}
                    placement={"top"}
                  />
                </label>
                <label className="card-price">
                  {dasboardOverviewModel.ytdPerformance.percentage}%
                  {dasboardOverviewModel.ytdPerformance.isPositive ? (
                    <span className="u-color-success">
                      <TrendingUpIcon />
                    </span>
                  ) : (
                    <span className="u-color-danger">
                      <TrendingDownIcon />
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="base-data-card seccondary-data-card-side-bg u-justifyCenter u-marginAm cash-card-porfolio u-alignItems u-alignContentCenter">
              <div className="u-flexColumn">
                <label className="card-label">
                  {t("Portfolio.sectionTwo.Cash Disponible para invertir")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.availableCash)}
                  .-
                </label>
              </div>
            </div>
            <div className="base-data-card seccondary-data-card-side-bg u-justifyCenter u-marginAm cash-card-porfolio u-alignItems u-alignContentCenter">
              <div className="u-flexColumn">
                <label className="card-label">
                  {t("Portfolio.sectionTwo.Cash invertido")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.investedCash)}
                  .-
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="u-displayFlex chart-bottomline">
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex actives-diversification-donutchart">
          <div className="u-flexColumn u-Width100 card-graph-title">
            <div className="card-graph-title">
              {t("Portfolio.sectionTwo.Diversificación de Activos")}
            </div>

            <DonutChart data={assetDiversification} />
          </div>
        </div>
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex participants-evolution-barchart">
          <div className="u-flexColumn u-Width100">
            <div className="u-color-title-card-secondary">
              <div className="card-graph-title">
                {t("Portfolio.sectionTwo.Evolución de Participantes")}
              </div>
              <div className="card-subtitle u-marginLl">
                {participantEvolution.isPositive ? (
                  <span className="u-color-success">
                    <NorthIcon />
                    {roundToTwoDecimals(participantEvolution.percentage)}%
                  </span>
                ) : (
                  <span className="u-color-danger">
                    <SouthIcon />
                    {roundToTwoDecimals(participantEvolution.percentage)}%
                  </span>
                )}
                <span className="u-marginHs">
                  {t("Portfolio.sectionTwo.de adheridos desde")} 2021
                </span>
              </div>              
            </div>
            <HorizontalGradientBarChart
              participantEvolutionDTO={
                participantEvolution.participantEvolutionDTO
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
