import React, { useState, useEffect } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import CustomLineChart from "../../../components/MultipleLinesChart/MultipleLinesChart";
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";
import CardTitleWSelect from "../../../components/CardTitleWSelect/CardTitleWSelect";
import {
  getOverviewData,
  getHistoricalPerformanceData,
} from "../../../services/FinancialServices";
import { initialDasboardOverviewModel } from "../../../models/DasboardOverviewModel";
import { initialHistoricalPerformanceData } from "../../../models/HistoricalPerformanceModel";
import { formatCurrency } from "../../../helpers/currencyFormatter";
import "./subSectionOne.scss";
import { useTranslation } from "react-i18next";

export default function SubSectionOne({ companyId }) {
  const [dasboardOverviewModel, setDasboardOverviewModel] = useState(
    initialDasboardOverviewModel
  );
  const [historicalPerformanceData, setHistoricalPerformanceData] = useState(
    initialHistoricalPerformanceData
  );
  const [interval, setInterval] = useState(200);
  const { t } = useTranslation();
  
  useEffect(() => {
    if (companyId) {
      getOverviewData(companyId).then((res) => {
        const dashboardOverview = res?.data?.dashboardOverview;
        if (dashboardOverview) {
          setDasboardOverviewModel(dashboardOverview);
        }
      });
      getHistoricalPerformanceData(companyId, "max", undefined).then(
        (response) => {
          const historicalPerformanceResponse =
            response?.data?.historicalPerformance;
          if (historicalPerformanceResponse) {
            setHistoricalPerformanceData(historicalPerformanceResponse);
          }
        }
      );
    }
  }, [companyId]);

  const handleSelectChange = (value) => {
    getHistoricalPerformanceData(companyId, value).then((response) => {
      const historicalPerformanceResponse =
        response?.data?.historicalPerformance;
      if (historicalPerformanceResponse) {
        setHistoricalPerformanceData(historicalPerformanceResponse);
        if (value === "max") {
          setInterval(300);
        } else if (value === "year") {
          setInterval(50);
        } else if (value === "month") {
          setInterval(20);
        } else if (value === "week") {
          setInterval(10);
        }
      }
    });
  };

  return (
    <div className="dashboard-section-one">
      <div className="u-displayFlex u-flexWrap u-marginAm top-cards-container">
        {/* lowres-first-line */}
        <div className="u-displayFlex lowres-first-line">
          <div className="base-data-card seccondary-data-card-bg u-justifyCenter u-marginAm mobile-card u-alignItems actual-wallet">
            <div className="u-flexColumn ">
              <label className="card-label">
                {t("Dashboard.sectionOne.Valor Actual de la cartera")}
              </label>
              <label className="card-price">
                U$D{" "}
                {formatCurrency(dasboardOverviewModel.currentPortfolioValue)}.-
              </label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-flexRow mobile-card u-alignItems initial-value">
            <div className="card-icon-container">
              <BarChartIcon />
            </div>

            <div className="u-flexColumn u-marginHm">
              <label className="card-label u-color-title-card">
                {t("Dashboard.sectionOne.Valor Inicial 2021")}
              </label>
              <label className="card-price">
                U$D {formatCurrency(dasboardOverviewModel.initialValue2021)}.-
              </label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex only-mobile mobile-card-seccondary u-alignItems lowres-second-line ytd-percentage">
            <div className="u-flexColumn">
              <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                {t("Dashboard.sectionOne.Rendimiento")} YTD %
                <DarkToolTip
                  content={t("Dashboard.sectionOne.tooltip1")}
                  placement={"top"}
                />
              </label>
              <label className="card-price">
                {dasboardOverviewModel.ytdPerformance.percentage}%
                {dasboardOverviewModel.ytdPerformance.isPositive ? (
                  <span className="u-color-success">
                    <TrendingUpIcon />
                  </span>
                ) : (
                  <span className="u-color-danger">
                    <TrendingDownIcon />
                  </span>
                )}
              </label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-flexRow mobile-card u-alignItems first-year-value">
            <div className="card-icon-container">
              <AttachMoneyIcon />
            </div>
            <div className="u-flexColumn u-marginHm">
              <label className="card-label u-color-title-card">
                {t("Dashboard.sectionOne.Valor a principio de año")}
              </label>
              <label className="card-price">
                U$D {formatCurrency(dasboardOverviewModel.beginningOfYearValue)}
                .-
              </label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex non-mobile-data u-alignItems ytd-balance">
            <div className="u-flexColumn">
              <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                {t("Dashboard.sectionOne.Rendimiento")} YTD $
                <div>
                  <DarkToolTip
                    content={t("Dashboard.sectionOne.tooltip1")}
                    placement={"top"}
                  />
                </div>
              </label>
              <label className="card-price">
                U$D{" "}
                {formatCurrency(dasboardOverviewModel.ytdPerformance.valueUSD)}
                .-
                {dasboardOverviewModel.ytdPerformance.isPositive ? (
                  <span className="u-color-success">
                    <TrendingUpIcon />
                  </span>
                ) : (
                  <span className="u-color-danger">
                    <TrendingDownIcon />
                  </span>
                )}
              </label>
            </div>
          </div>
        </div>

        <div className="u-displayFlex lowres-second-line">
          <div className="base-data-card default-data-card-bg u-justifyCenter u-marginAm u-displayFlex non-mobile-data mobile-card-seccondary u-alignItems lowres-second-line ytd-percentage">
            <div className="u-flexColumn">
              <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                {t("Dashboard.sectionOne.Rendimiento")} YTD %
                <DarkToolTip
                  content={t("Dashboard.sectionOne.tooltip1")}
                  placement={"top"}
                />
              </label>
              <label className="card-price">
                {dasboardOverviewModel.ytdPerformance.percentage}%
                {dasboardOverviewModel.ytdPerformance.isPositive ? (
                  <span className="u-color-success">
                    <TrendingUpIcon />
                  </span>
                ) : (
                  <span className="u-color-danger">
                    <TrendingDownIcon />
                  </span>
                )}
              </label>
            </div>
          </div>
          {/* <---- ----> */}
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm u-displayFlex lowres-cash-card  u-alignItems lowres-second-line">
            <div className="u-flexColumn">
              <div className="u-flexColumn u-marginLm-12">
                <label className="card-label">
                  {t("Dashboard.sectionOne.Cash Disponible para invertir")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.availableCash)}
                  .-
                </label>
              </div>
            </div>
          </div>
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm u-displayFlex lowres-cash-card  u-alignItems lowres-second-line">
            <div className="u-flexColumn">
              <div className="u-flexColumn u-marginLm-12">
                <label className="card-label">
                  {t("Dashboard.sectionOne.Cash invertido")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.investedCash)}
                  .-
                </label>
              </div>
            </div>
          </div>
          {/* <---- ----> */}
        </div>
      </div>
      <div className="u-displayFlex u-flexWrap u-marginAm dashboard-second-line u-alignItems u-alignItems u-alignItems">
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex areaLine-graph-card">
          <div className="u-flexColumn u-Width100">
            <div className="u-displayFlex u-alignItems areaLine-card-labels">
              <h3 className="card-graph-title">
                {t("Dashboard.sectionOne.Rendimiento Histórico")}
              </h3>
              <span className="u-marginHm-12 non-mobile-data">
                {t("Dashboard.sectionOne.Valor en")} USD |
              </span>
              <div className="u-displayFlex u-alignItems u-Width100 u-justifySpaceBetween">
                <div className="u-marginRm-12 non-mobile-data">
                  <svg width="15" height="7">
                    <g>
                      <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                    </g>
                  </svg>
                  <span>{t("Dashboard.sectionOne.Rendimiento Acumulado")}</span>
                </div>

                {/* <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#4AA785"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Participantes</span>
                  </div>

                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#F14D4D"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Empresa</span>
                  </div> */}
                <div>
                  <CardTitleWSelect
                    customTitle={null}
                    onSelectChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>
            <div className="u-displayFlex u-alignItems mobile-datacheck">
              <span className="u-marginHm-12 value">
                {" "}
                {t("Dashboard.sectionOne.Valor en")} USD |
              </span>
              <div className="u-marginRm-12">
                <svg width="15" height="7">
                  <g>
                    <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                  </g>
                </svg>
                <span>{t("Dashboard.sectionOne.Rendimiento Acumulado")}</span>
              </div>
            </div>
            <div className="main-chart-container u-marginAm">
              <CustomLineChart
                data={historicalPerformanceData}
                interval={interval}
              />
            </div>
          </div>
        </div>
        <div className="u-flexColumn wallet-cards">
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card u-alignContentCenter">
            <div className="u-flexColumn cash-card-direction">
              <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                <WalletOutlinedIcon />
              </div>
              <div className="u-flexColumn u-marginLm-12">
                <label className="card-label">
                  {t("Dashboard.sectionOne.Cash Disponible para invertir")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.availableCash)}
                  .-
                </label>
              </div>
            </div>
          </div>
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card u-alignContentCenter">
            <div className="u-flexColumn cash-card-direction">
              <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                <AttachMoneyIcon />
              </div>
              <div className="u-flexColumn u-marginLm-12">
                <label className="card-label">
                  {t("Dashboard.sectionOne.Cash invertido")}
                </label>
                <label className="card-price u-paddingBm">
                  U$D {formatCurrency(dasboardOverviewModel.cash.investedCash)}
                  .-
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
