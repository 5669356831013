import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { CardOneItems } from "../SubSectionTwo/CardOne/CardOneItems";
import DarkToolTip from "../ToolTip/DarkToolTip";

import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MobileTabConent2({ value }) {
  return (
    <CustomTabPanel value={value} index={1}>
      <div>
        <div className=" u-Width100 u-color-bright-blue  u-paddingVl">
          Movimientos de cartera
        </div>
        <div className="card-items u-Width100 u-displayFlex u-Height100 ">
          <CardOneItems
            icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
            descripcion={"Valor de la Cartera 2021"}
            value={108.82}
            bg={"default-data-card-bg"}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<CurrencyExchangeOutlinedIcon className="sub-card-icon" />}
            descripcion={"Valor a Principio de Años"}
            value={428.63}
            bg={"default-data-card-bg"}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<ListAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Valor de cuota parte"}
            value={4.83}
            bg={"default-data-card-bg"}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Valor $ cuota parte"}
            value={95.06}
            bg={"default-data-card-bg"}
            valueType={""}
            titleColor ={"u-color-title-card"}
          />

          <CardOneItems
            icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Rendimitnos YTD $"}
            value={30.17}
            valueType={""}
            bg={"tertiary-data-card-side-no-bg"}
            titleColor ={"u-color-title-card"}
            tooltip={
              <DarkToolTip
                content={`YTD "Year to Date" Desde Enero este año `}
                placement={"top"}
                toolTipIconColor={"white"}
              />
            }
          />
          <CardOneItems
            icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Rendimiento YTD %"}
            value={6.58}
            valueType={"%"}
            bg={"tertiary-data-card-side-no-bg"}
            titleColor ={"u-color-title-card"}
            tooltip={
              <DarkToolTip
                content={`YTD "Year to Date" Desde Enero este año `}
                placement={"top"}
                toolTipIconColor={"white"}
              />
            }
          />
        </div>
      </div>
    </CustomTabPanel>
  );
}
