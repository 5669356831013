import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PENSION_DEV_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const currentUser = sessionStorage.getItem("CURRENT_USER");
    if (currentUser) {
      const data = JSON.parse(currentUser);
      config.headers["Authorization"] = `Bearer ${data.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
