import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageDropdownMenu.scss";

//assets

import flag_en from "../../assets/flag_en.svg";
import flag_es from "../../assets/flag_es.svg";
import flag_ptr from "../../assets/flg_ptr.svg";

const DropdownLanguage = ({ currentLang }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(currentLang);
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null); // Referencia para el contenedor del menú
  const buttonRef = useRef(null);

  const handleLangChange = (evt) => {
    const lang = evt.target.value;
    setLanguage(lang);
    localStorage.setItem("lang", lang); // Guarda el idioma en localStorage
    i18n.changeLanguage(lang);
    setOpenMenu(false);
  };
  let handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) && // Si el clic no está dentro del menú
      buttonRef.current &&
      !buttonRef.current.contains(event.target) // Si el clic no está dentro del botón
    ) {
      setOpenMenu(false); // Cerrar el menú
    }
  };

  useEffect(() => {
    const defaultLang = localStorage.getItem("lang");
    if (defaultLang) {
      setLanguage(defaultLang);
    } else {
      setLanguage("es");
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick); // Limpia el evento al desmontar el componente
    };
  }, []);

  return (
    <div className="languageMenuIcon">
      <i ref={buttonRef} onClick={() => setOpenMenu((prev) => !prev)}>
        {language === "es" && (
          <img className="lngicon" src={flag_es} alt="Spanish" />
        )}
        {language === "en" && (
          <img className="lngicon" src={flag_en} alt="English" />
        )}
        {language === "ptr" && (
          <img className="lngicon" src={flag_ptr} alt="Portuguese" />
        )}
      </i>

      {openMenu && (
        <div ref={menuRef} className="dropdown">
          <ul>
            <div className="u-displayFlex dropDownOptions">
              <option className="lang1" value="es" onClick={handleLangChange}>
                ES
              </option>
              <img className="lngIconSmall" src={flag_es} alt="Spanish" />
            </div>
            <div className="u-displayFlex dropDownOptions">
              <option className="lang2" value="en" onClick={handleLangChange}>
                EN
              </option>
              <img className="lngIconSmall" src={flag_en} alt="English" />
            </div>
            <div className="u-displayFlex dropDownOptions">
              <option className="lang3" value="ptr" onClick={handleLangChange}>
                PTR
              </option>
              <img className="lngIconSmall" src={flag_ptr} alt="Portuguese" />
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownLanguage;
