import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = forwardRef((_, ref) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const showSnackbar = (newMessage, newSeverity = "error") => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    showSnackbar,
  }));

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});

export default CustomSnackbar;
