import axios from "axios";
import axiosInstance from "./BaseServiceInstance";

export async function logIn(email, password, onError) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PENSION_DEV_URL}/v1/api/user/login`,
      {
        email,
        password,
      }
    );
    const loginData = response.data;

    sessionStorage.setItem("CURRENT_USER", JSON.stringify(loginData));
    sessionStorage.setItem("isAuthenticated", "true");

    const userResponse = await axiosInstance.get(
      `/v1/api/user/${loginData.id}`
    );

    if (userResponse?.data?.qpsId && userResponse?.data?.roles[0]?.name) {
      const updatedData = {
        ...loginData,
        qpsId: userResponse.data.qpsId,
        role: userResponse.data.roles[0].name,
        companyId: userResponse.data.companyId,
      };

      sessionStorage.setItem("CURRENT_USER", JSON.stringify(updatedData));
    }

    return true;
  } catch (error) {
    //data for local testing:
    // const data = {
    //   id: 4,
    //   token: 'dasinasndasidnoasdiyusdf',
    //   refreshToken: 'dasinasndasidnoasdiyusdf',
    // }
    // sessionStorage.setItem("CURRENT_USER", JSON.stringify(data));
    // sessionStorage.setItem("isAuthenticated", "true");
    // return true;

    const errorMessage =
      error?.response?.data?.message || "Login failed. Please try again.";
    if (onError) onError(errorMessage);

    return false;
  }
}

export async function logOut(onError) {
  try {
    const sessionData = sessionStorage.getItem("CURRENT_USER");
    const data = JSON.parse(sessionData);

    const response = await axios.post(
      `${process.env.REACT_APP_PENSION_DEV_URL}/v1/api/user/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Refresh-Token": data.refreshToken,
        },
      }
    );

    sessionStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("isAuthenticated");
    localStorage.setItem("logoutEvent", Date.now());

    return response ?? true;
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Logout failed. Please try again.";
    if (onError) onError(errorMessage);

    sessionStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("isAuthenticated");
    localStorage.setItem("logoutEvent", Date.now());

    return false;
  }
}

export function checkAuthentication() {
  const currentUser = sessionStorage.getItem("CURRENT_USER");
  return !!currentUser;
}

export async function refreshToken() {
  try {
    const sessionData = sessionStorage.getItem("CURRENT_USER");
    const data = JSON.parse(sessionData);

    const response = await axios.post(
      `${process.env.REACT_APP_PENSION_DEV_URL}/v1/api/user/refresh-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.refreshToken}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
}
