import React, { useState, useEffect } from "react";
import "./MainUsuarios.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SearchIcon from "@mui/icons-material/Search";
import SubSectionOne from "./subSectionOne/subSectionOne";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import MobileTabsMenu from "../../components/MobileTabsMenu/MobileTabsMenu";
import MobileProfileBar from "../../components/MobileProfileCard/MobileProfileBar";
// Models
import { initialPortfolioMovementsModel } from "../../models/PortFolioMovementsModel";
import { initialDasboardOverviewModel } from "../../models/DasboardOverviewModel";
//import { initialHistoricalPerformanceData } from "../../models/HistoricalPerformanceModel";
import { initialAssetDiversificationModel } from "../../models/AssetDiversificationModel";
import { UserModel } from "../../models/UserModel";

// API
import {
  getPortfolioMovementsData,
  getOverviewData,
  //getHistoricalPerformanceData,
  getAssetDiversificationData,
} from "../../services/FinancialServices";
import { getUserByCuil } from "../../services/UserStatisticsService";

export default function MainUsuarios({ companyId }) {
  const [cuilNumber, setCuilNumber] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [portfolioMovements, setPortfolioMovements] = useState(
    initialPortfolioMovementsModel
  );
  const [dasboardOverviewModel, setDasboardOverviewModel] = useState(
    initialDasboardOverviewModel
  );
  // const [historicalPerformanceData, setHistoricalPerformanceData] = useState(
  //   initialHistoricalPerformanceData
  // );
  const [assetDiversification, setAssetDiversification] = useState(
    initialAssetDiversificationModel
  );
  const [userSearch, setUserSearch] = useState(UserModel);

  useEffect(() => {
    if (companyId && isDataLoaded) {
      getOverviewData(companyId, userSearch?.qpsId).then((res) => {
        const dashboardOverview = res?.data?.dashboardOverview;
        if (dashboardOverview) {
          setDasboardOverviewModel(dashboardOverview);
        }
      });
      getPortfolioMovementsData(companyId, userSearch?.qpsId).then(
        (response) => {
          const dataResponse = response?.data?.portfolioMovements;
          if (dataResponse) {
            setPortfolioMovements(dataResponse);
          }
        }
      );
      // getHistoricalPerformanceData(companyId, "max", userSearch?.qpsId).then(
      //   (response) => {
      //     const historicalPerformanceResponse =
      //       response?.data?.historicalPerformance;
      //     if (historicalPerformanceResponse) {
      //       setHistoricalPerformanceData(historicalPerformanceResponse);
      //     }
      //   }
      // );
      getAssetDiversificationData(companyId, userSearch?.qpsId).then(
        (response) => {
          const dataResponse = response?.data?.assetDiversificationDTO;
          if (dataResponse) {
            setAssetDiversification(dataResponse);
          }
        }
      );
    }
  }, [companyId, isDataLoaded, userSearch?.qpsId]);

  const handleCuilSearch = () => {
    getUserByCuil(cuilNumber).then((userData) => {
      const dataResponse = userData?.data;
      if (dataResponse) {
        setUserSearch(dataResponse);
        setIsDataLoaded(true);
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCuilSearch();
    }
  };

  return (
    <div className="u-displayGrid main-usuarios">
      <div className="usuarios-header display_column">
        <HeaderBar
          headerTitle={"Usuarios"}
          headerTitleInfo={"Resumen del Portfolio por Perfil"}
        />
      </div>

      {!isDataLoaded ? (
        <div className="u-displayFlex u-alignItems u-alignContentCenter cuil-search base-data-card default-data-card-bg ">
          <SearchIcon onClick={handleCuilSearch} />
          <input
            type="text"
            value={cuilNumber}
            onChange={(e) => setCuilNumber(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ingresa tu número de Cuil"
          />
        </div>
      ) : (
        <div>
          <div className="u-displayFlex display_column wrap web-display">
            <div>
              <SubSectionOne
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}                
                assetDiversification={assetDiversification}
              />
            </div>
            <div>
              <SubSectionTwo
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}
                portfolioMovements={portfolioMovements}
              />
            </div>
          </div>
          <div className="mobile-display">
            <MobileProfileBar />
            <MobileTabsMenu />
          </div>
        </div>
      )}

      {/*uncomment to test*/}
      <div>
          {/* <div className="u-displayFlex display_column wrap web-display">
            <div>
              <SubSectionOne
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}
                historicalPerformanceData={historicalPerformanceData}
                assetDiversification={assetDiversification}
              />
            </div>
            <div>
              <SubSectionTwo
                companyId={companyId}
                dasboardOverviewModel={dasboardOverviewModel}
                portfolioMovements={portfolioMovements}
              />
            </div>
          </div> */}

          <div className="mobile-display">
            <MobileProfileBar />
            <MobileTabsMenu />
          </div>
        </div>


      <div className="mobile-display">
        <MobileBottomBar />
      </div>
    </div>
  );
}
