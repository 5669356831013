import React from "react";
import "./subSectionOne.scss";
import testCompanyLogo from "../../../assets/test-company.svg";
import { useTranslation } from "react-i18next";
//MUI components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

//icons
import EditIcon from "@mui/icons-material/Edit";

export default function SubSectionOne() {
  const { t } = useTranslation();

  return (
    <div className="u-flexColumn ">
      <div className="u-displayFlex u-justifyBetween u-alignItems config-logobox">
        <div className="base-data-card default-data-card-bg config-form-container u-mobileMargin u-marginAm">
          <div className=" u-config-logo-border company-card">
            <div className="u-flexColumn ">
              <div className="u-displayFlex u-justifyEnd">
                <div className=" ">
                  <EditIcon
                    sx={{
                      backgroundColor: "#929da9a3",
                      borderRadius: "100%",
                      padding: "10%",
                      margin: "5px",
                    }}
                  />
                </div>
              </div>
              <div className="u-justifyCenter">
                <img
                  src={testCompanyLogo}
                  alt="Company Logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="u-displayFlex u-justifyBetween u-alignItems config-userdata">
        <div className="base-data-card default-data-card-bg u-marginAm config-form-container u-mobileMargin  ">
          <div>
            <h4 className="need-help-font-styles ">
              {t("Config.Cambiar contraseña")}
            </h4>
          </div>
          <div>
            <Box
              component="form"
              sx={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                paddingTop: "20px",
                display: "grid",
                gap: "20px",
              }}
              noValidate
              autoComplete="off"
            >
              <div className=" gridColumns">
                <TextField
                  id="outlined-read-only-input"
                  label="Email"
                  type="Email"
                  defaultValue={"email@email.com"}
                  fullWidth
                  disabled
                  variant="filled"
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
                <TextField
                  id="outlined-password-input"
                  label={t("Config.Contraseña")}
                  type="password"
                  defaultValue={"***********************"}
                  fullWidth
                  disabled
                  variant="filled"
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
