import React, { useState, useRef, useEffect } from "react";
import CustomSnackbar from "../../components/CustomSnackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import "./ProfileDropDownMenu.scss";
import { logOut } from "../../services/AuthService";

export default function ProfileDropDownMenu() {
  const snackbarRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleLogOut = async (e) => {
    e.preventDefault();
    const success = await logOut((error) => {
      snackbarRef.current.showSnackbar(error, "error");
    });

    if (success) {
      snackbarRef.current.showSnackbar("Logout successful!", "success");
      navigate("/new-login", { replace: true });
    }
  };

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="profileMenu">
      <CustomSnackbar ref={snackbarRef} />
      <i onClick={() => setOpenMenu((prev) => !prev)}>
        <img
          className="profile_Icon"
          src="https://avatar.iran.liara.run/public/32"
          alt="Profile_avatar"
        />
      </i>

      {openMenu && (
        <div ref={menuRef} className="dropDownMenu">
          <ul>
            <div className="u-displayFlex dropDownOptions">
              <div
                ref={buttonRef}
                onClick={handleLogOut}
                className="text-black"
              >
                LOG OUT
              </div>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}
