export function formatCurrency(value) {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return value;

    const roundedValue = Math.round(numberValue);
    
    const formattedInteger = String(roundedValue).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedInteger;
}



export function roundToTwoDecimals(value) {
    return parseFloat(value.toFixed(2));
}

