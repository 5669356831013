import React, { useState } from "react";
import "./MainPortfolio.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SubSectionOne from "./subSectionOne/subSectionOne";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import { useTranslation } from "react-i18next";

export default function MainPortfolio({ companyId }) {
  const [selectedPortfolioType, setSelectedPortfolioType] =
    useState("Conservadora");
  const { t } = useTranslation();

  return (
    <div className="main-portfolio porfolio-mobile-display">
      <div className="portfolio-header u-Width90">
        <HeaderBar
          headerTitle={t("Portfolio.Header.Portfolio")}
          headerTitleInfo={t(
            "Portfolio.Header.Resumen del Portfolio por Perfil"
          )}
        />
      </div>

      <div className="u-displayFlex mobile-column wrap main-portfolio-content">
        <div>
          <SubSectionOne
            companyId={companyId}
            selectedPortfolioType={selectedPortfolioType}
            setSelectedPortfolioType={setSelectedPortfolioType}
          />
        </div>
        <div>
          <SubSectionTwo
            companyId={companyId}
            selectedPortfolioType={selectedPortfolioType}
          />
        </div>
      </div>

      <div>
        <MobileBottomBar />
      </div>
    </div>
  );
}
