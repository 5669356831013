import React from "react";
import "./subSectionTwo.scss";
import { ActionButton } from "../../../components/ActionButton/ActionButton";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useTranslation } from "react-i18next";
export default function SubSectionTwo() {
  const { t } = useTranslation();
  return (
    <div className="u-displayFlex config-mobile-display contact-card-section ">
      <div className="base-data-card tertiary-data-card-side-bg u-marginAm u-alignContentCenter u-custom-config-card-1   ">
        <div className="u-flexColumn need-help-font-styles">
          <ManageAccountsIcon />
          <h3 className="card-label">
            {t("Config.Card1.Necesitas hablar con un asesor de Criteria?")}
          </h3>
          <span className="card-sub-label u-marginBxl u-custom-config-card-text-2 ">
            {t(
              "Config.Card1.Selecciona Contactanos y te redirigiremos con un asesor"
            )}
          </span>
          <ActionButton
            buttonType="button"
            buttonText={t("Config.Card1.Contactanos")}
            buttonColor="#252F40"
            buttonBackground="u-white-background"
          />
        </div>
      </div>

      <div className="base-data-card seccondary-data-card-bg u-marginAm u-alignContentCenter u-custom-config-card-2 ">
        <div className="u-flexColumn need-help-font-styles">
          <HeadsetMicOutlinedIcon />
          <h3 className="card-label">
            {t("Config.Card2.Tenes problemas con la plataforma?")}
          </h3>
          <span className="card-sub-label u-marginBxl u-custom-config-card-text">
            {t(
              "Config.Card2.Selecciona Contactanos y te redirigiremos con un profesional de soporte tecnico"
            )}
          </span>
          <ActionButton
            buttonType="button"
            buttonText={t("Config.Card2.Contactanos")}
            buttonColor="#252F40"
            buttonBackground="u-white-background"
          />
        </div>
      </div>
      
    </div>
  );
}
