import React from "react";
import "./subSectionOne.scss";

import DragDropFiles from "../../../components/DragDropFiles/DragDropFiles";
import TemplateDownloadButton from "../../../components/TemplateDownloadButton/TemplateDownloadButton";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { useTranslation } from "react-i18next";
export default function SubSectionOne({ companyId }) {
  const { t } = useTranslation();
  return (
    <div className="u-displayGrid u-alignItems">
      <div className="u-displayFlex u-alignItems u-flexCenter u-justifySpaceBetween need-help-font-styles">
        <h3 className="need-help-font-styles u-marginHl subtitle">
          {t(
            "GestionEmpresas.subSectionOne.Selecciona y descarga el template .xlsx para la carga masiva de participantes"
          )}
        </h3>
        <div>
          <TemplateDownloadButton />
        </div>
      </div>

      <div className="base-data-card default-data-card-bg ">
        <div className="u-displayFlex u-alignItems u-justifySpaceBetween">
          <div className="u-displayFlex u-justifyStart gap-utility ">
            <div className="icon-border">
              <CloudUploadOutlinedIcon sx={{ width: 42, height: 42 }} />
            </div>
            <div className="u-flexColumn u-alignItemStart u-marginHm">
              <h3 className="need-help-font-styles">
                {t("GestionEmpresas.subSectionOne.Carga de archivos")}
              </h3>
              <span className="need-help-font-styles">
                {t(
                  "GestionEmpresas.subSectionOne.Seleccione y cargue los archivos de su elección"
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="u-Width100 u-paddingBxl">
          <hr class="solid"></hr>
        </div>

        <div className="">
          <DragDropFiles companyId={companyId} />
        </div>
      </div>
    </div>
  );
}
