import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ResetPassword from "../views/Login/ResetPassword/ResetPassword.js";
import ForgotPassword from "../views/Login/ForgotPassword/ForgotPassword.js";
import CreatePassword from "../views/Login/CreatePassword/CreatePassword.js";
import EmailConfirmation from "../views/Login/EmailConfirmation/EmailConfirmation.js";
import Login from "../views/Login/Login.js";
import Layout from "../views/Layout/Layout.js";
import { checkAuthentication } from "../services/AuthService";
import { getUserRole } from "../services/UserStatisticsService.js";

export default function RouterComponent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAuthState = async () => {
      const authStatus = checkAuthentication();
      setIsAuthenticated(authStatus);

      if (authStatus) {
        const role = await getUserRole();
        setUserRole(role);
      }

      setIsLoading(false);
    };

    fetchAuthState();

    const handleStorageChange = () => fetchAuthState();

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("logoutEvent", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("logoutEvent", handleStorageChange);
    };
  }, []);

  const isAdmin = userRole === "ROLE_ADMIN";

  if (isLoading) {
    
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route path="/new-login" element={<Login />} />
      <Route
        path="/"
        element={
          isAuthenticated ? (
            isAdmin ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/usuarios" />
            )
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/dashboard"
        element={
          isAuthenticated ? (
            isAdmin ? (
              <Layout page="dashboard" />
            ) : (
              <Navigate to="/usuarios" />
            )
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/portfolio"
        element={
          isAuthenticated ? (
            isAdmin ? (
              <Layout page="portfolio" />
            ) : (
              <Navigate to="/usuarios" />
            )
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/usuarios"
        element={
          isAuthenticated ? (
            <Layout page="usuarios" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/benchmark"
        element={
          isAuthenticated ? (
            <Layout page="benchmark" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/configuracion"
        element={
          isAuthenticated ? (
            <Layout page="configuracion" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/gestion-empresas"
        element={
          isAuthenticated ? (
            isAdmin ? (
              <Layout page="gestion-empresas" />
            ) : (
              <Navigate to="/usuarios" />
            )
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
}
