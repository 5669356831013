import React from "react";
import "./MainConfiguracion.scss";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SubSectionOne from "../Configuracion/subSectionOne/subSectionOne";
import SubSectionTwo from "../Configuracion/subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";

import { useTranslation } from "react-i18next";

export default function MainConfiguracion() {
  const { t } = useTranslation();

  return (
    <div className="u-displayGrid main-config ">
      <div className="config-header">
        <HeaderBar
          headerTitle={t("Config.Configuración & Soporte")}
          headerTitleInfo={t("Config.Resumen del Portfolio por Perfil")}
        />
      </div>

      <div className="u-displayFlex config-wrap config-structure-page">
        <div className="">
          {" "}
          <SubSectionOne />
        </div>
        <div>
          {" "}
          <SubSectionTwo />
        </div>
      </div>

      <div className="mobile-view">
        <MobileBottomBar />
      </div>
    </div>
  );
}
