import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { roundToTwoDecimals, formatCurrency } from '../../helpers/currencyFormatter';
import { removePrefix } from '../../helpers/stringValueFormatter';
import './DonutChart.scss';

export default function DonutChart({ data }) {
  const colors = ['#042E5D', '#85D1EF', '#95A4FC', '#a1e3cb'];

  const [radius, setRadius] = useState({ inner: 50, outer: 80 });
  const [legendPosition, setLegendPosition] = useState({ layout: 'vertical', align: 'right', verticalAlign: 'middle' });

  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth < 1450) {
        setRadius({ inner: 30, outer: 50 });
        setLegendPosition({ layout: 'horizontal', align: 'center', verticalAlign: 'bottom' });
      } else {
        setRadius({ inner: 50, outer: 80 });
        setLegendPosition({ layout: 'vertical', align: 'right', verticalAlign: 'middle' });
      }
    };

    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, []);

  if (!data || data.length === 0) {
    return <div style={{ textAlign: 'center', padding: '20px' }}>No Hay datos para mostrar</div>;
  }

  const chartData = data.map(item => ({
    name: item.label,
    value: parseFloat(item.value),
    valueMoney: parseFloat(item.valueMoney || item.totalMoney),
  }));

  const tooltipFormatter = (value, name, props) => {
    const moneyValue = props.payload.valueMoney;
    return [`${roundToTwoDecimals(value)}% ($${formatCurrency(moneyValue)})`, name];
  };

  return (
    <ResponsiveContainer height="100%">
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          paddingAngle={2}
          startAngle={-25}
          endAngle={335}
          cx="50%"
          cy="50%"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} cornerRadius={5} />
          ))}
        </Pie>

        <Tooltip formatter={tooltipFormatter} />

        <Legend
          layout={legendPosition.layout}
          align={legendPosition.align}
          verticalAlign={legendPosition.verticalAlign}
          formatter={(value, entry) => (
            <span className="custom-legend-text">
              {`${removePrefix(value)}: ${roundToTwoDecimals(entry.payload.value)}%`}
            </span>
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
