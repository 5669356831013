import React from "react";
import "./TemplateDownloadButton.scss";
//MUI components
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
//service
import { getTemplates } from "../../services/TemplateDownloadService";
import { useTranslation } from "react-i18next";
export default function TemplateDownloadButton() {
  const { t } = useTranslation();
  const handleDownloadTemplate = async (e) => {
    e.preventDefault();
    const value = e.target.value;
    const resp = await getTemplates(value);
    return resp;
  };

  return (
    <div>
      {" "}
      <Box sx={{ minWidth: 100 }}>
        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Templates.xlsl
          </InputLabel>
          <NativeSelect defaultValue={1} fullWidth>
            <option disabled value={1}>
              {t(
                "GestionEmpresas.TemplateDownloadButton.Seleccione el template"
              )}
            </option>
            <option onClick={handleDownloadTemplate} value={"r01-participante"}>
              {t("GestionEmpresas.TemplateDownloadButton.Participantes")}
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r02-esquema-de-inversion"}
            >
              {t("GestionEmpresas.TemplateDownloadButton.Esquema-de-inversion")}
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r03-cotizacion-de-los-fondos"}
            >
              {t(
                "GestionEmpresas.TemplateDownloadButton.Cotizacion-de-los-fondos"
              )}
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r12-movimientos-en-cuenta"}
            >
              {t(
                "GestionEmpresas.TemplateDownloadButton.Movimientos-en-cuenta"
              )}
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r15-contribuciones-todos-los-participantes"}
            >
              {t(
                "GestionEmpresas.TemplateDownloadButton.Contribuciones-todos-los-participantes"
              )}
            </option>
          </NativeSelect>
        </FormControl>
      </Box>
    </div>
  );
}
