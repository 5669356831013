import React, { useState, useEffect } from "react";
import "./subSectionTwo.scss";
//iconos
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
//Selector de lenguaje
// import { useTranslation } from "react-i18next";
//componentes
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";
//import CardTitleWSelect from '../../../components/CardTitleWSelect/CardTitleWSelect';
import VerticalBarsChart from "../../../components/VerticalBarsChart/VerticalBarsChart";
import {
  getPortfolioGraphData,
  getPortfolioMovementsData,
} from "../../../services/FinancialServices";
import { initialPortfolioMovementsModel } from "../../../models/PortFolioMovementsModel";
import { initialPortfolioGraphModel } from "../../../models/PorfolioGraphModel";
import { formatCurrency } from "../../../helpers/currencyFormatter";
import { useTranslation } from "react-i18next";

export default function SubSectionTwo({ companyId }) {
  const [portfolioMovements, setPortfolioMovements] = useState(
    initialPortfolioMovementsModel
  );
  const [portfolioGraphData, setportfolioGraphData] = useState(
    initialPortfolioGraphModel
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (companyId) {
      getPortfolioMovementsData(companyId).then((response) => {
        const dataResponse = response?.data?.portfolioMovements;
        if (dataResponse) {
          setPortfolioMovements(dataResponse);
        }
      });
      getPortfolioGraphData(companyId).then((response) => {
        const graphResponse = response?.data?.portfolioMovement;
        if (graphResponse) {
          setportfolioGraphData(graphResponse);
        }
      });
    }
  }, [companyId]);

  return (
    <div className="u-displayFlex u-flexWrap u-Width100 sectionTwoMain u-marginAm ">
      {/* cardOne 1 */}
      <div className="base-data-card default-data-card-bg u-marginAm wallet-movements">
        <div className="card-graph-title u-Width100">
          {t("Dashboard.sectionTwo.Movimientos de cartera")}
        </div>
        <div className="card-items u-Width100 u-displayFlex">
          <CardOneItems
            icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
            descripcion={t("Dashboard.sectionTwo.Depositos")}
            value={formatCurrency(portfolioMovements.deposits)}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<CurrencyExchangeOutlinedIcon className="sub-card-icon" />}
            descripcion={t("Dashboard.sectionTwo.Extracciones")}
            value={formatCurrency(portfolioMovements.withdrawals)}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<AssessmentOutlinedIcon className="sub-card-icon" />}
            descripcion={t("Dashboard.sectionTwo.Valor de cuota parte")}
            value={portfolioMovements.shareValue}
            valueType={"U$D"}
            titleColor ={"u-color-title-card"}
          />
          <CardOneItems
            icon={<Groups3OutlinedIcon className="sub-card-icon" />}
            descripcion={t("Dashboard.sectionTwo.Participantes Adheridos")}
            value={portfolioMovements.enrolledParticipants}
            valueType={""}
            titleColor ={"u-color-title-card"}
          />
        </div>
      </div>

      {/* cardTwo 2 */}
      <div className="base-data-card u-Height100 u-Width100 default-data-card-bg u-marginAm composed-graph-card">
        <div className="card-graph-title">
          {t("Dashboard.sectionTwo.Valor de la Cartera")} (USD)
        </div>
        <div className="cardTwo-content u-marginVm-12 ">
          {/* <div className="cardTwo-header">
            <div className="cardTwo-header-value u-color-dark-blue">
              U$S { formatCurrency(portfolioGraphData.accumulatedBalance) } <span>Saldo Acumulado</span>
            </div>
          </div> */}
          <div className="chart-container u-alignContentCenter">
            <VerticalBarsChart
              depositsAndWithdrawals={portfolioGraphData.chart}
              portfolioEvolution={portfolioGraphData.portfolioEvolution}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
