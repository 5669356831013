import React, { useState, useEffect } from 'react';
import {  
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  CartesianGrid 
} from 'recharts';

export default function SimplifiedLineChart({data}) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!data || data.length === 0) {
    return <div style={{ textAlign: 'center', padding: '20px' }}>No Hay datos para mostrar</div>;
  }

  const tickFormatter = (value, index) => {
    if (!isSmallScreen) return value;
    if (index === 0 || index === Math.floor(data.length / 2) || index === data.length - 1) {
      return value;
    }
    return '';
  };

  return (
    <ResponsiveContainer width="100%" height={isSmallScreen ? 150 : 260}>
      <ComposedChart 
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >        
        <XAxis 
          dataKey="date"
          axisLine={false}
          tickLine={false}
          type="category"
          interval={20}
          padding={{ left: 30, right: 20 }}
          tickFormatter={tickFormatter}
        />
       
        <YAxis 
          yAxisId="left"          
          axisLine={false} 
          tickLine={false} 
          ticks={[0, 1, 2, 3]}
        />

        <Tooltip />
        
        
        <CartesianGrid strokeDasharray="3 3" />
        
        <Line 
          yAxisId="left"
          type="monotone" 
          dataKey="value" 
          stroke="#63ABFD" 
          strokeDasharray="3 4 5 2" 
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
