import React, { useEffect, useState, useRef } from "react";
import "./Login.scss";
import criteriaLogo from "../../assets/logo-lower-opacity.svg";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { InputPass } from "../../components/InputPass/InputPass";
import { useTranslation } from "react-i18next";
import { logIn } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/CustomSnackbar/CustomSnackbar";

export default function Login() {
  const snackbarRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const user = await logIn(email, password, (error) => {
      snackbarRef.current.showSnackbar(error, "error");
    });

    if (user) {
      snackbarRef.current.showSnackbar("Login successful!", "success");
      navigate("/dashboard");
      window.dispatchEvent(new Event("storage"));
    } else {
      snackbarRef.current.showSnackbar(
        "Invalid email or password. Please try again.",
        "error"
      );
    }
  };

  return (
    <div className="sup-main-container">
      <CustomSnackbar ref={snackbarRef} />
      <div className="main-container">
        <div className="container">
          <div className="titulo-text">
            <img src={criteriaLogo} alt="Criteria" className="logo" />
          </div>
          <div className="form-container">
            <div className="login-card u-background-light-blue">
              <h1 className="u-marginBs">{t("Login.signIn.SignIn")}</h1>
              <span className="u-color-white u-marginBl">
                {t("Login.signIn.Bienvenidos a Criteria Pension & Benefits")}
              </span>
              <span className="text-divider">
                {t("Login.signIn.Ingresa tus datos")}
              </span>
              <form className="u-flexColumn u-marginVm" onSubmit={handleLogin}>
                <InputPass
                  valueInput={email}
                  setValue={setEmail}
                  placeholder={t("Login.signIn.Ingresa tu email")}
                  inputType="email"
                  inputBackground="u-transparent-white-background"
                  inputColor="u-color-lightest-blue"
                  placeholderColor="u-placeholder-lightest-blue"
                />
                <InputPass
                  valueInput={password}
                  setValue={setPassword}
                  placeholder={t("Login.signIn.Ingresa tu contraseña")}
                  inputType="password"
                  inputBackground="u-transparent-white-background"
                  inputColor="u-color-lightest-blue"
                  placeholderColor="u-placeholder-lightest-blue"
                />
                <div className="forgot-password">
                  <a href="/forgot-password">
                    {t("Login.signIn.¿Olvidaste tu contraseña?")}
                  </a>
                </div>
                <ActionButton
                  buttonType="submit"
                  buttonText={t("Login.signIn.Ingresar")}
                  buttonColor="u-color-dark-blue"
                  buttonBackground="u-fog-white-background"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-container">
        @ 2024 Criteria | Pension & Benefits
      </div>
    </div>
  );
}
