import React from "react";
import "./HeaderSearchBar.scss";
import SearchIcon from "@mui/icons-material/Search";

import { useTranslation } from "react-i18next";

export const HeaderSearchBar = () => {
  const { t } = useTranslation();
  return (
    <div className="SearchBar">
      <div className="search u-displayFlex">
        <SearchIcon
          className="search-icon"
          sx={{
            height: "24px",
            backgroundColor: "white",
          }}
        />
        <input
          className="searchBar-Input"
          type="text"
          placeholder={t("Header.SearchBar.Buscar")}
        />
      </div>
    </div>
  );
};
