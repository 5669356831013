import React from "react";
import { useTranslation } from "react-i18next";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

export default function VerticalBarsChart({
  depositsAndWithdrawals,
  portfolioEvolution,
}) {
  const { t } = useTranslation();
  if (!depositsAndWithdrawals || depositsAndWithdrawals.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        No Hay datos para mostrar
      </div>
    );
  }

  const portfolioMap = portfolioEvolution.reduce(
    (acc, { date, finalAmount }) => {
      acc[date] = finalAmount;
      return acc;
    },
    {}
  );

  const allDates = Array.from(
    new Set([
      ...depositsAndWithdrawals.map(({ date }) => date),
      ...portfolioEvolution.map(({ date }) => date),
    ])
  );

  const formattedData = allDates
    .map((date) => {
      const depositsData =
        depositsAndWithdrawals.find((entry) => entry.date === date) || {};
      return {
        date,
        deposits: depositsData.deposits || null,
        withdrawals: depositsData.withdrawals || null,
        portfolioEvolution: portfolioMap[date] || null,
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const formatYAxisValue = (value) => {
    if (value < 0)
      return value <= -1000000
        ? `- ${Math.abs(value / 1000000).toFixed(0)} Mill.`
        : `- $${Math.abs(value)}`;
    return value >= 1000000
      ? `${(value / 1000000).toFixed(0)} Mill.`
      : `$${value}`;
  };

  return (
    <div style={{ margin: "20px" }}>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={formattedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            type="category"
            interval={200}
            padding={{ left: 30, right: 20 }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            yAxisId="left"
            tickFormatter={formatYAxisValue}
            ticks={[0, 1000000, 2000000, 3000000, 4000000, 5000000]}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={formatYAxisValue}
            ticks={[2000000, 4000000, 6000000, 8000000, 10000000, 12000000]}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            formatter={(value, name, props) => {
              const date = props.payload?.date || "Unknown date";
              const formattedValue = value
                ? `$${value.toLocaleString()}`
                : "N/A";
              return [formattedValue, `${name} (${date})`];
            }}
            labelFormatter={(label) => `Fecha: ${label}`}
          />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="deposits"
            stackId="a"
            fill="#A1E3CB"
            name={t("VerticalBarChart.Depósitos")}
            barSize={20}
          />
          <Bar
            yAxisId="left"
            dataKey="withdrawals"
            stackId="a"
            fill="#F14D4D"
            name={t("VerticalBarChart.Extracciones")}
            barSize={20}
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="portfolioEvolution"
            stroke="#2B3674"
            name={t("VerticalBarChart.Evolución de Portafolio")}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
