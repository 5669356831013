import React from "react";
import "./MainDashboard.scss";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import SubSectionThree from "./subSectionThree/subSectionThree";
import SubSectionOne from "./subSectionOne/subSectionOne";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import { useTranslation } from "react-i18next";

export default function MainDashboard({ companyId }) {
  const { t } = useTranslation();

  return (
    <div className="u-displayGrid section-two-main main-dashboard ">
      <div className="dashboard-header ">
        <HeaderBar
          headerTitle={t("Dashboard.Header.Dashboard")}
          headerTitleInfo={t(
            "Dashboard.Header.Vision General de Planes de Pension"
          )}
        />
      </div>
      <div className="mobile-wrap main-dashboard-content">
        <div>
          <SubSectionOne companyId={companyId} />
        </div>
        <div className="">
          <SubSectionTwo companyId={companyId} />
        </div>
        <div className="">
          <SubSectionThree companyId={companyId} />
        </div>
      </div>
      <div className="mobile-view">
        <MobileBottomBar />
      </div>
    </div>
  );
}
