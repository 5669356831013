import axios from "axios";

export async function getTemplates(value) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PENSION_DEV_URL}/v1/api/file/download/${value}`,
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    const fileBlob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    console.log(fileBlob);
    const url = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${value}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    return true;
  }
}
