import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./subSectionThree.scss";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import { ActionButton } from "../../../components/ActionButton/ActionButton";
import DonutChart from "../../../components/DonutChart/DonutChart";
//import CardTitleWSelect from '../../../components/CardTitleWSelect/CardTitleWSelect';
import { initialAssetDiversificationModel } from "../../../models/AssetDiversificationModel";
import { initialPortfolioDistributionModel } from "../../../models/PortfolioDistributionModel";
import {
  getAssetDiversificationData,
  getPortfolioDistributionData,
  getPortfolioMovementsData,
} from "../../../services/FinancialServices";
import { formatCurrency } from "../../../helpers/currencyFormatter";
import { initialPortfolioMovementsModel } from "../../../models/PortFolioMovementsModel";
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";
//iconos
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import { useTranslation } from "react-i18next";

export default function SubSectionThree({ companyId }) {
  const navigate = useNavigate();
  const [assetDiversification, setAssetDiversification] = useState(
    initialAssetDiversificationModel
  );
  const [portfolioDistribution, setPortfolioDistribution] = useState(
    initialPortfolioDistributionModel
  );
  const [portfolioMovements, setPortfolioMovements] = useState(
    initialPortfolioMovementsModel
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (companyId) {
      getPortfolioMovementsData(companyId).then((response) => {
        const dataResponse = response?.data?.portfolioMovements;
        if (dataResponse) {
          setPortfolioMovements(dataResponse);
        }
      });
      getAssetDiversificationData(companyId).then((response) => {
        const dataResponse = response?.data?.assetDiversificationDTO;
        if (dataResponse) {
          setAssetDiversification(dataResponse);
        } else {
          setAssetDiversification([]);
        }
      });
      getPortfolioDistributionData(companyId).then((response) => {
        const dataResponse = response?.data?.portfolioDistribution;
        if (dataResponse) {
          setPortfolioDistribution(dataResponse);
        } else {
          setPortfolioDistribution([]);
        }
      });
    } else {
      setAssetDiversification([]);
      setPortfolioDistribution([]);
    }
  }, [companyId]);

  return (
    <div>
      <div className="u-displayFlex u-flexWrap u-marginAm dashboard-third-block">
        {/*lowres*/}
        <div className="u-flexColumn lowres-display">
          <div className="base-data-card default-data-card-bg u-marginAm ">
            <div className="card-graph-title u-Width100">
              {t("Dashboard.sectionThree.Movimientos de cartera")}
            </div>
            <div className="card-items u-Width100 u-displayFlex">
              <CardOneItems
                icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
                descripcion={t("Dashboard.sectionTwo.Depositos")}
                value={formatCurrency(portfolioMovements.deposits)}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={
                  <CurrencyExchangeOutlinedIcon className="sub-card-icon" />
                }
                descripcion={t("Dashboard.sectionThree.Extracciones")}
                value={formatCurrency(portfolioMovements.withdrawals)}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={<AssessmentOutlinedIcon className="sub-card-icon" />}
                descripcion={t("Dashboard.sectionThree.Valor de cuota parte")}
                value={portfolioMovements.shareValue}
                valueType={"U$D"}
                titleColor ={"u-color-title-card"}
              />
              <CardOneItems
                icon={<Groups3OutlinedIcon className="sub-card-icon" />}
                descripcion={t(
                  "Dashboard.sectionThree.Participantes Adheridos"
                )}
                value={portfolioMovements.enrolledParticipants}
                valueType={""}
                titleColor ={"u-color-title-card"}
              />
            </div>
          </div>
          <div className="base-data-card seccondary-data-card-bg u-marginAm u-alignContentCenter contact-card">
            <div className="u-flexColumn need-help-font-styles">
              <HeadsetMicOutlinedIcon />
              <h3 className="card-label">
                {t("Dashboard.sectionThree.Necesitas ayuda?")}
              </h3>
              <span className="card-sub-label u-marginBxl">
                {t(
                  "Dashboard.sectionThree.Consulta nuestros canales de contactación"
                )}
              </span>
              <ActionButton
                buttonType="button"
                buttonText="Contactanos"
                buttonColor="#252F40"
                buttonBackground="u-white-background"
              />
            </div>
          </div>
        </div>
        {/*lowres*/}
        <div className="donut-cards u-displayFlex">
          <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex u-Width100 diversification-card">
            <div className="u-flexColumn u-Width100">
              <div className="card-graph-title">
                {t("Dashboard.sectionThree.Diversificación de Activos")}
              </div>
              <DonutChart data={assetDiversification} />
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex u-Width100 distribution-card">
            <div className="u-flexColumn u-Width100">
              <div className="card-graph-title">
                {t(
                  "Dashboard.sectionThree.Distribución del $ segun portafolio"
                )}
              </div>
              <DonutChart data={portfolioDistribution} />
            </div>
          </div>
        </div>

        <div className="base-data-card seccondary-data-card-bg u-marginAm u-alignContentCenter contact-card-HQ">
          <div className="u-flexColumn need-help-font-styles">
            <HeadsetMicOutlinedIcon />
            <h3 className="card-label">Necesitas ayuda?</h3>
            <span className="card-sub-label u-marginBxl">
              {t(
                "Dashboard.sectionThree.Consulta nuestros canales de contactación"
              )}
            </span>
            <form onSubmit={() => navigate("/configuracion")}>
              <ActionButton
                buttonType="submit"
                buttonText="Contactanos"
                buttonColor="#252F40"
                buttonBackground="u-white-background"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
