import React, { useEffect, useState } from "react";
import MainDashboard from "../Dashboard/MainDashboard";
import MainPortfolio from "../Portfolio/MainPortfolio.js";
import MainUsuarios from "../Usuarios/MainUsuarios.js";
import MainConfiguracion from "../Configuracion/MainConfiguracion.js";
import MainGestionEmpresas from "../GestionEmpresas/MainGestionEmpresas.js";
import MainBenchmark from "../Benchmark/MainBenchmark.js";
import { SideNav } from "../../components/SideNav/SideNav.js";
import { getUserRole, getCompanyId } from "../../services/UserStatisticsService";
import { refreshToken } from "../../services/AuthService.js";
import "./Layout.scss";

export default function Layout({ page }) {
  const [companyId, setCompanyId] = useState("");
  const [userRole, setUserRole] = useState(getUserRole());
  const [isAdmin, setIsAdmin] = useState(userRole === "ROLE_ADMIN");

  useEffect(() => {
    const currentUser = sessionStorage.getItem("CURRENT_USER");

    if (currentUser) {
      setUserRole(getUserRole());
      setIsAdmin(getUserRole() === "ROLE_ADMIN");
      setCompanyId(getCompanyId());
      
      // getUserById()
      //   .then((response) => {
      //     setCompanyId(response.data?.companyId);
      //   })
      //   .catch((error) => console.error("Failed to fetch user by ID:", error));

      
      const intervalId = setInterval(() => {
        refreshToken()
          .then((response) => {
            if (response && response.data) {
              const updatedUser = {
                ...JSON.parse(sessionStorage.getItem("CURRENT_USER")),
                token: response.data.token,
              };
              sessionStorage.setItem("CURRENT_USER", JSON.stringify(updatedUser));
            }
          })
          .catch((error) => {
            console.error("Failed to refresh token:", error);
          });
      }, 14 * 60 * 1000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const handleToggleView = (page) => {
    switch (page) {
      case "dashboard":
        return <MainDashboard companyId={companyId} />;
      case "portfolio":
        return <MainPortfolio companyId={companyId} />;
      case "usuarios":
        return <MainUsuarios companyId={companyId} />;
      case "benchmark":
        return <MainBenchmark />;
      case "configuracion":
        return <MainConfiguracion />;
      case "gestion-empresas":
        return <MainGestionEmpresas companyId={companyId} />;
      default:
        return null;
    }
  };

  return (
    <div className="u-displayGrid primary-view u-main-font-family">
      <div className="sidenav">
        <SideNav pageTitle={page} isAdmin={isAdmin} />
      </div>
      <div>{handleToggleView(page)}</div>
    </div>
  );
}
